<template>
  <div class="pipeline-filters travel-filters">
    <aRow class="fiter-collumns" :gutter="20">
      <aCol class="col1" :span="12">
        <aRow :gutter="20">
          <aCol :span="6">
            <div class="travel-input relative">
              <label class="filled">ID da oportunidade</label>
              <a-input
                placeholder="ID + Enter"
                v-model="optFilters.id"
                @pressEnter="onChangeEmitGetOpportunities()"
              />
            </div>
          </aCol>

          <aCol :span="18">
            <div class="travel-input">
              <label class="filled">Cliente </label>
              <a-auto-complete
                :data-source="
                  customers.list.map(
                    ({
                      first_name,
                      last_name,
                      id,
                      person_type,
                      trading_name,
                    }) => ({
                      value: id,
                      text:
                        person_type === 'Pessoa Física'
                          ? `${id} - ${first_name} ${last_name}`
                          : `${id} - ${trading_name}`,
                    })
                  )
                "
                v-model="customers.filters.customerName"
                style="width: 100%; height: 32px"
                placeholder="Buscar cliente..."
                @change="onChangeSearchCustomers"
                @select="customerSelected"
                @blur="customerSelected"
              />
            </div>
          </aCol>

          <aCol :span="12">
            <div class="travel-input">
              <label class="filled">Data de cadastro</label>

              <a-range-picker
                v-model="optFilters.period.selected"
                :format="['DD/MM/YYYY']"
                :value-format="'YYYY-MM-DD'"
              />
            </div>
          </aCol>

          <aCol :span="12">
            <div class="travel-input">
              <label class="filled">Data de fechamento</label>

              <a-range-picker
                v-model="optFilters.releaseDate.selected"
                :format="['DD/MM/YYYY']"
                :value-format="'YYYY-MM-DD'"
              />
            </div>
          </aCol>

          <aCol :span="24">
            <div class="travel-input">
              <label class="filled">Origem</label>
              <a-select
                class="travel-input"
                placeholder="Selecione"
                v-model="optFilters.origin.selected"
                show-search
                allow-clear
                mode="multiple"
                style="width: 100%"
                :disabled="
                  $store.state.userData.id == 46 ||
                  $store.state.userData.id == 45 ||
                  $store.state.userData.id == 44
                    ? true
                    : false
                "
              >
                <aSelectOption
                  v-for="(origin, index) in originsList"
                  :key="index"
                  :value="origin.name"
                >
                  {{ origin.name }}
                </aSelectOption>
              </a-select>
            </div>
          </aCol>

          <aCol :span="24">
            <div class="field travel-checkbox">
              <font class="label">Status</font>
              <a-checkbox-group
                style="width: 100%"
                v-model="optFilters.status.selected"
              >
                <a-checkbox
                  v-for="(item, index) of optFilters.status.list"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-checkbox>
              </a-checkbox-group>
            </div></aCol
          >

          <aCol :span="24">
            <div class="field travel-checkbox">
              <font class="label">Situação</font>
              <a-checkbox-group
                style="width: 100%"
                v-model="optFilters.situation.selected"
              >
                <a-checkbox
                  v-for="(item, index) of optFilters.situation.list"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-checkbox>
              </a-checkbox-group>
            </div></aCol
          >

          <aCol :span="24">
            <div class="field travel-checkbox">
              <font class="label relative">Tipo de cliente</font>
              <a-checkbox-group
                style="width: 100%"
                v-model="optFilters.customers.type"
              >
                <a-checkbox class="f12" value="Novo"> Novo </a-checkbox>
                <a-checkbox class="f12" value="Recorrente">
                  Recorrente
                </a-checkbox>
              </a-checkbox-group>
            </div>
          </aCol>
        </aRow>
      </aCol>

      <aCol :span="12">
        <aCol :span="24">
          <div class="travel-input relative">
            <label class="filled">Título</label>
            <a-input
              placeholder="Escreva..."
              v-model="optFilters.title"
              @pressEnter="onChangeEmitGetOpportunities()"
            />
          </div>
        </aCol>
        <aCol v-if="$root.isAdmin() || $root.isMilesManager()" :span="24">
          <div class="travel-input relative">
            <label class="filled">Usuários</label>
            <a-select
              class="travel-input"
              placeholder="Selecione um ou mais usuários"
              optionFilterProp="txt"
              mode="multiple"
              v-model="optFilters.users.selected"
              show-search
              style="width: 100%"
            >
              <a-select-option
                v-for="(item, index) of optFilters.users.list"
                :key="index"
                :value="item.value"
                :txt="item.label"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </div>
        </aCol>

        <aCol :span="6">
          <div class="travel-input">
            <label class="filled">Empresas</label>
            <a-select
              class="travel-input"
              placeholder="Selecione"
              v-model="optFilters.companies.selected"
              show-search
              allow-clear
              style="width: 100%"
              @change="onSelectCompany"
            >
              <a-select-option
                v-for="(item, index) of companies.list"
                :key="index"
                :value="item.id"
              >
                {{ item.trading_name }}
              </a-select-option>
            </a-select>
          </div>
        </aCol>
        <aCol :span="18">
          <div class="travel-input">
            <label class="filled">Filiais</label>
            <a-select
              class="travel-input"
              placeholder="Selecione"
              mode="multiple"
              v-model="optFilters.companyBranches.selected"
              show-search
              style="width: 100%"
            >
              <a-select-option
                v-for="(item, index) of companyBranches.list"
                :key="index"
                :value="item.id"
              >
                {{ item.id }} - {{ item.name }}
              </a-select-option>
            </a-select>
          </div>
        </aCol>

        <aCol v-if="$root.isAdmin()" :span="6">
          <div class="travel-input relative">
            <label class="filled">UTM Source</label>
            <a-input placeholder="Escreva" v-model="optFilters.utm.source" />
          </div>
        </aCol>

        <aCol v-if="$root.isAdmin()" :span="6">
          <div class="travel-input relative">
            <label class="filled">UTM Campaign</label>
            <a-input placeholder="Escreva" v-model="optFilters.utm.campaign" />
          </div>
        </aCol>

        <aCol v-if="$root.isAdmin()" :span="6">
          <div class="travel-input relative">
            <label class="filled">UTM Medium</label>
            <a-input placeholder="Escreva" v-model="optFilters.utm.medium" />
          </div>
        </aCol>

        <aCol v-if="$root.isAdmin()" :span="6">
          <div class="travel-input relative">
            <label class="filled">UTM Term</label>
            <a-input placeholder="Escreva" v-model="optFilters.utm.term" />
          </div>
        </aCol>

        <aCol v-if="$root.isAdmin()" :span="6">
          <div class="travel-input relative">
            <label class="filled">UTM Content</label>
            <a-input placeholder="Escreva" v-model="optFilters.utm.content" />
          </div>
        </aCol>
      </aCol>
    </aRow>
    <a-divider class="mt-0 mb-15" />
    <a-button
      class="mb-20"
      type="primary"
      @click="onChangeEmitGetOpportunities()"
      ><a-icon type="search" /> Filtrar Oportunidades</a-button
    >
  </div>
</template>

<script>
// libs
import _ from "lodash";

import customerMixins from "@/components/customers/mixins/customerMixins";
import companyMixins from "@/mixins/companies/companyMixins";
import companyBranchMixins from "@/mixins/company-branches/companyBranchMixins";

export default {
  name: "PipelineFilters",
  props: {
    funnelId: Number,
    optFilters: Object,
  },
  mixins: [customerMixins, companyMixins, companyBranchMixins],
  data() {
    return {
      originsList: [],
    };
  },
  mounted() {
    this.getUsers();
    this.getCompanyBranches();
    this.getCompanies();
    this.$http
      .get(`/category-entry/list?page=1&category_id=26&per_page=300`)
      .then(({ data }) => {
        this.originsList = data.data;
      });
  },
  methods: {
    onChangeSearchCustomers: _.debounce(function (e) {
      if (e.length > 1) {
        this.getCustomers();
      }
    }, 600),
    onChangeEmitGetOpportunities() {
      this.optFilters.funnelId = this.funnelId;
      this.$emit("onChangeEmitGetOpportunities", this.optFilters);
    },
    onSelectCompany(val) {
      this.companyBranches.filters.companyId = val;
      this.companyBranches.list = [];
      this.getCompanyBranches();
    },
    customerSelected(customer) {
      this.customers.filters.customerName = customer;
      this.optFilters.customers.selected = [customer];
    },
    getUsers() {
      this.$http
        .get("/user/list?page=1&per_page=300&user_id=1")
        .then(({ data }) => {
          this.optFilters.users.list = data.data.map((user) => {
            return {
              label: `${user.id} - ${user.first_name} ${user.last_name}`,
              value: user.id,
            };
          });
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
  },
};
</script>

<style lang="sass">
.field.travel-checkbox
  position: relative
  padding-top: 10px
  .ant-checkbox-wrapper
    width: 25%
    margin: 0 !important
    padding: 4px 11px 0px 0
    white-space: nowrap
    overflow: hidden
    font-size: 11px
    text-overflow: ellipsis
</style>

<style lang="sass" scoped>
.fiter-collumns
  .col1
    padding-right: 20px !important
    border-right: 1px solid #ddd

.travel-filters
  .field
    .label
      position: absolute
      font-size: 10px
      font-weight: 600
      color: #434a53 !important
      top: -10px
      z-index: 1

.pipeline-filters
  padding: 10px 20px 0px !important
  border: 2px solid #eee !important
  margin: 0 !important
  border-radius: 6px !important
</style>
