<template>
  <div class="pipeline">
    <aPageHeader class="pd-20 pb-0" style="height: 66px">
      <template #title>
        <h1>
          <img
            src="@/assets/images/dashboard/opportunities/pipeline.png"
            alt="img"
          />
          Pipeline
          <span
            class="reload-btn c-pointer ml-5 mr-10"
            @click="onClickChangePipelineActiveTab(pipelineActiveTab)"
          >
            <a-tooltip>
              <template slot="title"> Atualizar oportunidades </template>
              <img
                src="@/assets/images/contract/sync.png"
                alt="reload"
                width="14"
              />
            </a-tooltip>
          </span>

          <aSelect
            v-if="funnelDefaultValue"
            class="funnel-selector"
            :defaultValue="funnelDefaultValue"
            :showArrow="false"
            @change="onChangeFunnel"
          >
            <aSelectOption
              v-for="(funnel, index) in opportunityFunnels.list"
              :key="index"
              :value="funnel.id"
            >
              {{ funnel.name }}
            </aSelectOption>
          </aSelect>

          <aSelect
            class="funnel-selector origin-selector"
            placeholder="Origem"
            v-model="optOrigin"
            :showArrow="false"
            @change="onChangeOptOrigin"
            :disabled="
              $store.state.userData.id == 46 ||
              $store.state.userData.id == 45 ||
              $store.state.userData.id == 44
                ? true
                : false
            "
            allowClear
          >
            <aSelectOption
              v-for="(origin, index) in originsList"
              :key="index"
              :value="origin.name"
            >
              {{ origin.name }}
            </aSelectOption>
          </aSelect>
        </h1>
      </template>

      <template #extra>
        <aButton
          type="primary"
          ghost
          @click="openCreateOpportunityModal = true"
        >
          <img
            class="mr-5"
            src="@/assets/images/dashboard/opportunities/new.png"
            width="15"
            alt="img"
          />
          Nova oportunidade
        </aButton>

        <aButton type="primary" ghost @click="onClickOpenFilters">
          <img
            src="@/assets/images/dashboard/opportunities/filter.png"
            width="11"
            alt="img"
          />
        </aButton>

        <aButton
          v-if="pipelineActiveTab === 'pipeline'"
          type="primary"
          ghost
          @click="onClickChangePipelineActiveTab('list')"
        >
          <img
            src="@/assets/images/dashboard/opportunities/list.png"
            width="15"
            alt="img"
          />
        </aButton>

        <aButton
          v-if="pipelineActiveTab === 'list'"
          type="primary"
          ghost
          @click="onClickChangePipelineActiveTab('pipeline')"
        >
          <a-icon type="pic-center" />
        </aButton>
      </template>
    </aPageHeader>

    <section class="pd-20 pt-0" v-if="openFilters">
      <PipelineFilters
        :funnelId="funnelDefaultValue"
        :optFilters="optFilters"
        @onChangeEmitGetOpportunities="onChangeEmitGetOpportunities"
      />
    </section>

    <section class="mt-10" v-if="pipelineActiveTab === 'pipeline'">
      <div v-if="!opportunitiesFunnelSteps.loading" class="funnel-kanban">
        <div class="stage-columns">
          <div
            class="stage"
            v-for="(step, index) in opportunitiesFunnelSteps.list"
            :key="index"
          >
            <FunnelColumn
              v-if="opportunitiesFunnelSteps.list.length > 0"
              :step="step"
              :funnelId="`${opportunitiesFunnelSteps.filters.funnelId}`"
              :optFilters="optFilters"
              :usersList="usersList"
              ref="FunnelColumn"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="pd-20" v-if="pipelineActiveTab === 'list'">
      <PipelineList
        :funnelId="opportunitiesFunnelSteps.filters.funnelId"
        :optFilters="optFilters"
        ref="PipelineList"
      />
    </section>

    <aModal
      v-model="openCreateOpportunityModal"
      :width="800"
      :footer="false"
      @cancel="openCreateOpportunityModal = false"
    >
      <template #title>
        <img
          class="mr-5"
          src="@/assets/images/dashboard/opportunities/new.png"
          width="18"
          alt="img"
        />
        Nova Oportunidade
      </template>
      <CreateOpportunityModal
        v-if="openCreateOpportunityModal"
        :funnelId="funnelDefaultValue"
        @loadOpportunitiesList="loadOpportunitiesList"
      />
    </aModal>
  </div>
</template>

<script>
import axios from "axios";

import opportunityFunnelsMixins from "@/components/pipeline/mixins/opportunityFunnelsMixins";
import opportunitiesFunnelStepsMixins from "@/components/pipeline/mixins/opportunitiesFunnelStepsMixins";
import opportunityMixins from "@/components/pipeline/mixins/opportunityMixins";
import CreateOpportunityModal from "@/components/pipeline/modals/CreateOpportunityModal.vue";
import PipelineFilters from "@/components/pipeline/sections/PipelineFilters.vue";
import PipelineList from "@/components/pipeline/sections/PipelineList.vue";
import FunnelColumn from "@/components/pipeline/sections/FunnelColumn.vue";

export default {
  name: "Pipeline",
  components: {
    PipelineFilters,
    PipelineList,
    FunnelColumn,
    CreateOpportunityModal,
  },
  mixins: [
    opportunityMixins,
    opportunityFunnelsMixins,
    opportunitiesFunnelStepsMixins,
  ],
  data() {
    return {
      pipelineActiveTab: "pipeline",
      openCreateOpportunityModal: false,
      openFilters: false,
      originsList: [],
      optOrigin: undefined,
      optFilters: {},
      usersList: [],
    };
  },
  beforeMount() {
    this.getUsersList();
  },
  mounted() {
    this.opportunityFunnels.filters.orderBy = "funnel_order";
    this.opportunityFunnels.filters.order = "ascend";
    this.getOpportunityFunnels();

    this.$http
      .get(`/category-entry/list?page=1&category_id=26&per_page=300`)
      .then(({ data }) => {
        this.originsList = data.data;
      });

    setTimeout(() => {
      this.opportunitiesFunnelSteps.filters.funnelId =
        this.opportunityFunnels.list.length > 0
          ? parseInt(this.opportunityFunnels.list[0].id)
          : 1;

      this.opportunitiesFunnelSteps.filters.status.selected = ["Ativado"];
      this.getOpportunitiesFunnelSteps();

      this.opportunity.filters.funnelId =
        this.opportunitiesFunnelSteps.filters.funnelId;
      this.optFilters = this.opportunity.filters;

      setTimeout(() => {
        this.optFilters.situation.selected = ["Nenhuma"];
        this.optFilters.status.selected = ["Aberto"];

        if (
          this.$store.state.userData.id == 46 ||
          this.$store.state.userData.id == 45 ||
          this.$store.state.userData.id == 44
        ) {
          this.onChangeOptOrigin(["Site VR", "Site Maldivas"]);
        } else {
          this.onChangeOptOrigin(undefined);
        }
      }, 800);
    }, 200);
  },
  methods: {
    getUsersList() {
      this.$http
        .get(
          `/user/list?page=1&per_page=300&status=1&order=desc&order-by=created`
        )
        .then(({ data }) => {
          this.usersList = data.data;
        });
    },
    getStagnations() {
      axios.get(`https://api.viajar.tur.br/v1/opportunity/stagnation-cron`);
    },
    onChangeEmitGetOpportunities(filters) {
      this.optFilters = filters;
      this.optFilters.step.selected = [];

      if (this.pipelineActiveTab === "list")
        this.$refs.PipelineList.$emit("onSelectOptOrigin");
      if (
        this.pipelineActiveTab === "pipeline" &&
        this.$refs.FunnelColumn !== undefined
      )
        this.$refs.FunnelColumn.forEach((funnelCol) => {
          setTimeout(() => {
            funnelCol.$emit("onSelectOptOrigin");
          }, 100);
        });
    },
    onClickChangePipelineActiveTab(tab) {
      setTimeout(() => {
        this.pipelineActiveTab = tab;
        this.optFilters.step.selected = [];
        if (tab === "list")
          setTimeout(() => {
            this.$refs.PipelineList.$emit("onSelectOptOrigin");
          }, 1000);

        if (tab === "pipeline")
          setTimeout(() => {
            this.optFilters.situation.selected = ["Nenhuma"];
            if (this.$refs.FunnelColumn !== undefined)
              this.$refs.FunnelColumn.forEach((funnelCol) => {
                setTimeout(() => {
                  funnelCol.$emit("onSelectOptOrigin");
                }, 100);
              });
          }, 300);
      }, 300);
    },
    onChangeOptOrigin(origin) {
      this.optFilters.origin.selected = origin ? [origin] : [];
      this.optFilters.step.selected = [];
      if (
        this.pipelineActiveTab === "pipeline" &&
        this.$refs.FunnelColumn !== undefined
      )
        this.$refs.FunnelColumn.forEach((funnelCol) => {
          setTimeout(() => {
            funnelCol.$emit("onSelectOptOrigin", origin ? origin : "");
          }, 100);
        });

      if (this.pipelineActiveTab === "list")
        this.$refs.PipelineList.$emit(
          "onSelectOptOrigin",
          origin ? origin : ""
        );
    },
    onClickOpenFilters() {
      if (this.openFilters) {
        this.openFilters = false;
      } else {
        this.openFilters = true;
      }
    },
    onChangeFunnel(val) {
      this.opportunitiesFunnelSteps.filters.funnelId = val;
      this.funnelDefaultValue = val;
      this.getOpportunitiesFunnelSteps();

      this.optFilters.funnelId = val;
      this.optFilters.step.selected = [];

      setTimeout(() => {
        if (this.pipelineActiveTab === "list")
          this.$refs.PipelineList.$emit("onSelectOptOrigin");
        if (
          this.pipelineActiveTab === "pipeline" &&
          this.$refs.FunnelColumn !== undefined
        )
          this.$refs.FunnelColumn.forEach((funnelCol) => {
            setTimeout(() => {
              funnelCol.$emit("onSelectOptOrigin");
            }, 100);
          });
      }, 300);
    },
    loadOpportunitiesList() {
      this.openCreateOpportunityModal = false;
      this.onChangeFunnel(this.funnelDefaultValue);
    },
  },
};
</script>

<style lang="sass">
.funnel-selector
  width: 200px
  margin-left: 10px
  .ant-select-selection__rendered
    width: 200px
    line-height: 30px
  .ant-select-selection--single
    border: 0
    width: 200px
    font-size: 16px
    letter-spacing: -0.5px
    font-weight: 500

.origin-selector
  .ant-select-selection--single
    font-size: 13px
    letter-spacing: 0px
    font-weight: 400
</style>

<style lang="sass" scoped>
.funnel-kanban
  padding: 0 15px
  width: 100%
  overflow-x: auto
  .stage-columns
    display: flex
    align-items: flex-start
    .stage
      flex: 1
      position: relative
      background: #fff
      overflow: hidden
      width: 250px
      max-width: 250px
      min-width: 250px
      min-height: 800px
      border-right: 1px solid #ecf0f1
      margin: 0
      padding: 0
</style>
