var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stage-box"},[_c('div',{staticClass:"stage-title"},[_c('div',{staticClass:"title-wrapper"},[_c('h2',[_c('aRow',{attrs:{"type":"flex","justify":"space-between"}},[_c('aCol',[_vm._v(" "+_vm._s(_vm.step.name)+" ")]),_vm._v(" "),_c('aCol',[_vm._v(" "+_vm._s(_vm.opportunity.pagination.total)+" ")])],1)],1),_vm._v(" "),_c('div',{staticClass:"total"},[_c('aRow',{attrs:{"type":"flex","justify":"space-between"}},[_c('aCol',[_vm._v("\n            R$\n            "+_vm._s(_vm.opportunity.meta.total_value ? _vm.opportunity.meta.total_value : 0)+"\n          ")]),_vm._v(" "),_c('aCol',[_vm._v(_vm._s(_vm.step.probability_winning_percentage)+"% ")])],1)],1)])]),_vm._v(" "),_c('div',{ref:"opportunitiesList",class:`opportunities-list`,attrs:{"id":`opportunities-list-${_vm.step.id}`},on:{"scroll":_vm.updateScrollHeight}},[(_vm.opportunity.loading && _vm.opportunity.pagination.page == 1)?_c('div',{staticClass:"a-center"},[_c('aSpin',{attrs:{"size":"small"}})],1):_vm._e(),_vm._v(" "),_c('draggable',{attrs:{"group":"opportunities","data-step":_vm.step.name,"dataColor":_vm.step.color,"list":_vm.opportunity.listLoadMore},on:{"end":_vm.endDrag}},_vm._l((_vm.opportunity.listLoadMore),function(opportunity,i){return _c('OpportunityCard',{key:i,attrs:{"data-id":opportunity.id,"opportunity":opportunity,"stepColor":_vm.step.color,"stagnationAlert":_vm.stagnationAlert(opportunity),"usersList":_vm.usersList}})}),1),_vm._v(" "),(
        _vm.opportunity.pagination.page <= _vm.opportunity.pagination.totalPages &&
        _vm.opportunity.loading
      )?_c('div',{staticClass:"a-center"},[_c('aSpin',{attrs:{"size":"small"}})],1):_vm._e(),_vm._v(" "),(_vm.opportunity.pagination.page != 0)?_c('div',{staticClass:"a-center"},[(
          _vm.opportunity.pagination.page <= _vm.opportunity.pagination.totalPages &&
          !_vm.opportunity.loading
            ? 'none'
            : ''
        )?_c('aButton',{staticClass:"f12 load-more-button",attrs:{"size":"small"},on:{"click":_vm.loadMore}},[_vm._v("carregar mais")]):_vm._e()],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }