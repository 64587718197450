<template>
  <div class="stage-box">
    <div class="stage-title">
      <div class="title-wrapper">
        <h2>
          <aRow type="flex" justify="space-between">
            <aCol> {{ step.name }} </aCol>
            <aCol> {{ opportunity.pagination.total }} </aCol>
          </aRow>
        </h2>
        <div class="total">
          <aRow type="flex" justify="space-between">
            <aCol>
              R$
              {{
                opportunity.meta.total_value ? opportunity.meta.total_value : 0
              }}
            </aCol>
            <aCol>{{ step.probability_winning_percentage }}% </aCol>
          </aRow>
        </div>
      </div>
    </div>

    <div
      ref="opportunitiesList"
      :id="`opportunities-list-${step.id}`"
      :class="`opportunities-list`"
      @scroll="updateScrollHeight"
    >
      <div
        v-if="opportunity.loading && opportunity.pagination.page == 1"
        class="a-center"
      >
        <aSpin size="small" />
      </div>

      <draggable
        group="opportunities"
        :data-step="step.name"
        :dataColor="step.color"
        :list="opportunity.listLoadMore"
        @end="endDrag"
      >
        <OpportunityCard
          v-for="(opportunity, i) in opportunity.listLoadMore"
          :key="i"
          :data-id="opportunity.id"
          :opportunity="opportunity"
          :stepColor="step.color"
          :stagnationAlert="stagnationAlert(opportunity)"
          :usersList="usersList"
        />
        <!-- step.stagnation_alert_days  -->
      </draggable>

      <div
        v-if="
          opportunity.pagination.page <= opportunity.pagination.totalPages &&
          opportunity.loading
        "
        class="a-center"
      >
        <aSpin size="small" />
      </div>

      <div v-if="opportunity.pagination.page != 0" class="a-center">
        <aButton
          v-if="
            opportunity.pagination.page <= opportunity.pagination.totalPages &&
            !opportunity.loading
              ? 'none'
              : ''
          "
          class="f12 load-more-button"
          size="small"
          @click="loadMore"
          >carregar mais</aButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import opportunityMixins from "../mixins/opportunityMixins";
import OpportunityCard from "../sections/OpportunityCard.vue";

export default {
  name: "FunnelColumn",
  props: {
    step: Object,
    funnelId: String,
    optFilters: Object,
    usersList: Array,
  },
  components: {
    draggable,
    OpportunityCard,
  },
  mixins: [opportunityMixins],
  mounted() {
    this.$on("onSelectOptOrigin", this.filterByOptOrigin);
  },
  methods: {
    filterByOptOrigin() {
      this.optFilters.step.selected = [this.step.name];
      this.opportunity.filters = this.optFilters;
      this.opportunity.pagination.page = 1;
      this.opportunity.pagination.perPage = 10;
      this.opportunity.listLoadMore = [];
      this.getOpportunities(false, true);
    },
    loadMore() {
      this.optFilters.step.selected = [this.step.name];
      this.opportunity.filters = this.optFilters;
      this.getOpportunities(false, true);
    },
    updateScrollHeight(e) {
      e;
      const divHeight = document.getElementById(
        `opportunities-list-${this.step.id}`
      ).scrollHeight;
      const divHeightLive =
        document.getElementById(`opportunities-list-${this.step.id}`)
          .scrollTop + 780;

      if (divHeight == divHeightLive)
        if (
          this.opportunity.pagination.page <=
          this.opportunity.pagination.totalPages
        )
          if (!this.opportunity.loading) this.loadMore();
    },
    stagnationAlert(opt) {
      let stagnationAlert = false,
        today = new Date().getTime(),
        updatedOptAt =
          opt.updated !== "0000-00-00 00:00:00"
            ? new Date(opt.updated)
            : new Date(opt.created),
        stagnationDays = updatedOptAt.setDate(
          updatedOptAt.getDate() + parseInt(this.step.stagnation_alert_days)
        );

      if (today > stagnationDays) {
        stagnationAlert = true;
      }

      return stagnationAlert;
    },
    endDrag(opt) {
      if (opt.from.dataset.step != opt.to.dataset.step) {
        this.$http
          .post("/opportunity/update-field", {
            id: opt.clone.dataset.id,
            field: "step",
            value: opt.to.dataset.step,
          })
          .then(({ data }) => {
            data;
            this.$http.post("/log/create", {
              user_id: this.$store.state.userData.id,
              module_id: opt.clone.dataset.id,
              module: "opportunity",
              action: "change-opportunity-funnel-step",
              description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} passou a oportunidade da etapa <b>${opt.from.dataset.step}</b> para a etapa <b>${opt.to.dataset.step}</b>.`,
            });
          })
          .catch(({ response }) => {
            response;
          });
      }
    },
  },
};
</script>

<style lang="sass" scoped>
div[data-step]
  min-height: 600px

.stage-box
  .opportunities-list
    height: 780px
    padding: 10px
    overflow: auto
    min-height: 700px
    //background: #eee
  .stage-title
    padding: 5px 10px 0
    .title-wrapper
      border-bottom: 1px solid #ecf0f1
      width: 100%
      padding-bottom: 5px
      h2
        font-size: 12px
        margin: 0
        font-weight: 600
      .total
        font-size: 10px
        color: #abb7b7
</style>
