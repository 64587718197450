<template>
  <div class="list">
    <a-table
      class="travel-table"
      :columns="optTableColumns"
      :data-source="opportunity.list"
      :loading="opportunity.loading"
      :pagination="false"
      :scroll="{ x: 1300 }"
      @change="opportunityTableChange"
    >
      <a slot="name" slot-scope="text">{{ text }}</a>

      <div slot="customer" slot-scope="record">
        <div class="dotted-phrase upper">
          <div v-if="record.customer">
            <span
              v-if="record.customer.person_type == 'Pessoa Física'"
              class="ml-5"
              >{{ record.customer.id }} - {{ record.customer.first_name }}
              {{ record.customer.last_name }}</span
            >
            <span
              v-if="record.customer.person_type == 'Pessoa Jurídica'"
              class="ml-5"
              >{{ record.customer.id }} - {{ record.customer.trading_name }}
            </span>
          </div>
          <div v-else>{{ record.customer_name }}</div>
        </div>
      </div>

      <div slot="value" slot-scope="record">
        {{ record.value | formatPricePtBr }}
      </div>

      <div slot="mobile_phone" slot-scope="record">
        {{ record.mobile_phone | formatMobilePhone }}
      </div>

      <div slot="company_branch" slot-scope="record">
        {{ record.company_branch.name }}
      </div>

      <div slot="user" slot-scope="record">
        <a-tooltip>
          <template slot="title">
            {{ record.user.first_name }} {{ record.user.last_name }}
          </template>
          <span id="avatar">
            <a-avatar
              v-if="record.user.avatar"
              :src="record.user.avatar"
              :size="30"
            />
            <a-avatar
              v-else
              class="upper"
              style="color: #f56a00; background-color: #fde3cf"
            >
              {{ record.user.first_name.substring(0, 1)
              }}{{ record.user.last_name.substring(0, 1) }}
            </a-avatar>
          </span>
        </a-tooltip>
      </div>

      <div class="travel-actions" slot="action" slot-scope="record">
        <a-tooltip placement="top" title="Editar">
          <a
            class="edit"
            @click="$router.push(`/opportunity/edit/${record.id}`)"
          >
            <a-icon type="edit-svg" />
          </a>
        </a-tooltip>

        <!-- <a-popconfirm
          title="Enviar para a lixeira?"
          ok-text="Sim"
          cancel-text="Não"
          placement="left"
          @confirm="sendOptToTrash(record.id)"
        >
          <a-icon class="red c-pointer ml-10" type="delete" />
        </a-popconfirm> -->
      </div>
    </a-table>

    <div class="a-center mb-60">
      <a-pagination
        show-size-changer
        :default-current="opportunity.pagination.page"
        :page-size.sync="opportunity.pagination.perPage"
        :total="opportunity.pagination.total"
        @change="changeOpportunityPage"
        @showSizeChange="changeOpportunityPageSize"
      >
      </a-pagination>
    </div>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";
import opportunityFunnelsMixins from "../mixins/opportunityFunnelsMixins";
import opportunityMixins from "../mixins/opportunityMixins";

export default {
  name: "PipelineList",
  props: {
    optOrigin: String,
    optFilters: Object,
    funnelId: Number,
  },
  mixins: [opportunityFunnelsMixins, opportunityMixins, formatThings],
  data() {
    return {
      optTableColumns: [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          class: "id",
          width: 65,
          sorter: true,
        },
        {
          title: "Etapa",
          dataIndex: "step",
          key: "step",
          width: 200,
          sorter: true,
        },
        {
          title: "Título",
          width: 250,
          dataIndex: "title",
          key: "title",
          sorter: true,
        },
        {
          title: "Cliente",
          width: 250,
          scopedSlots: { customRender: "customer" },
          key: "customer_name",
          sorter: true,
        },
        {
          title: "Celular",

          width: 150,
          key: "mobile_phone",
          scopedSlots: { customRender: "mobile_phone" },
          sorter: true,
        },
        {
          title: "Valor",
          width: 150,
          key: "value",
          scopedSlots: { customRender: "value" },
          sorter: true,
        },
        {
          title: "Empresa",
          width: 150,
          scopedSlots: { customRender: "company_branch" },
        },
        {
          title: "Usuário",
          width: 150,
          scopedSlots: { customRender: "user" },
        },
        {
          title: "",
          key: "action",
          align: "right",
          width: 90,
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  created() {
    this.$on("onSelectOptOrigin", this.filterByOptOrigin);
  },
  mounted() {
    this.opportunityFunnels.filters.orderBy = "funnel_order";
    this.opportunityFunnels.filters.order = "ascend";
    this.getOpportunityFunnels();

    this.opportunity.pagination.page = 1;
    this.opportunity.filters = this.optFilters;
  },
  methods: {
    filterByOptOrigin() {
      this.opportunity.pagination.page = 1;

      setTimeout(() => {
        if (Object.keys(this.optFilters).length > 0)
          this.opportunity.filters = this.optFilters;

        setTimeout(() => {
          this.getOpportunities();
        }, 100);
      }, 100);
    },
    sendOptToTrash(id) {
      this.$http
        .post("/opportunity/update-field", {
          id,
          field: "situation",
          value: "Lixeira",
        })
        .then(({ data }) => {
          data;
          this.$message.success(
            "Opportunidade enviada para lixeira com sucesso!"
          );

          this.$http
            .post("/log/create", {
              user_id: this.$store.state.userData.id,
              module_id: id,
              module: "opportunity",
              action: "trash-opportunity",
              description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} enviou a  oportunidade ID ${this.tempOpportunity.id} para <b>Lixeira</b>.`,
            })
            .then(({ data }) => {
              data;
            });

          this.getOpportunities(true);
        });
    },
  },
};
</script>

<style lang="sass">
.pipeline
  .travel-filters
    .ant-collapse-content-box
      padding: 0 16px !important
.funnel-selector
  width: 260px
  margin-left: 10px
  .ant-select-selection__rendered
    width: 260px
    line-height: 30px
  .ant-select-selection--single
    border: 0
    width: 160px
    font-size: 16px
    letter-spacing: -0.5px
    font-weight: 500
</style>

<style lang="sass" scoped>
.fiter-collumns
  .col1
    padding-right: 20px !important
    border-right: 1px solid #ddd

.travel-filters
  .ant-collapse-content-box
    padding: 0 16px !important
  .field.payments
    .ant-checkbox-wrapper
      width: 25%
      margin: 0 !important
      padding: 4px 11px 0px 0
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
  .field
    position: relative
    padding-top: 10px
    label
      font-size: 11px
      color: #999
    .label
      position: absolute
      font-size: 10px
      font-weight: 600
      color: #434a53 !important
      top: -10px
      z-index: 1
</style>
